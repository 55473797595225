.social {
  list-style-type: none;
}

.social>li {
  display: inline-block;
  cursor: pointer;
}

.social li {
  width: 34.4px;
  height: 34.4px;
  flex-shrink: 0;
  margin-right: 10px;
}

.social_footer {
  display: none;
}

@media screen and (max-width: 1023px) {
  .social_header {
    display: none;
  }

  .social_footer {
    display: block;
  }
}