:root {
  --main-bg-color: #94ADCA;
  --main-text-color: #FFF;
  --button-order-bg-color: #3D3D3D;
}

* {
  padding: 0;
  margin: 0;
}

html, body, .root, .page {
  width: 100%;
}

.page {
  position: relative;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'PhosphateSolid'; 
  src: url("/src/components/fonts/PhosphateSolid.ttf"); 
} 