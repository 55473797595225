.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
  overflow-y: hidden;
  visibility: hidden;
}

.display {
  visibility: visible;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
}

.modal_container {
  position: fixed;
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
  background: var(--base-white, #FFF);
  padding: 24px;
}

.modal_header {
  color: var(--secondary-dark-color, #54595E);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal_body {
  width: 100%;
  margin-top: 8px;
}

.modal_body_info {
  color: rgba(84, 89, 94, 0.60);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 24px;
}

.button_container {

  width: 100%;
  display: flex;
  justify-content: right;
}

.close_button_container {
  cursor: pointer;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 29px;
  background: var(--grey-medium, #E5E5E5);
}

.modal_button {
  width: 100%;
  cursor: pointer;
  margin-bottom: 8px;
  background: #94ADCA;
  display: flex;
  padding: 12px 28px;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  border-radius: 6px;
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  border: 0;
}