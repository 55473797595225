  .header {
    height: 150px;
    width: 100%;
  }

  .container {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 100px;
  }

  .phone {
    color: #14140C;
    font-family: Open Sans;
    font-size: 27.849px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
    color: #14140C;
    /* margin-left: 100px */
  }

  .header_logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 1023px) {
    header {
      width: 100%;
      margin: 0;
      height: 100px;
    }

    .phone {
      display: none;
    }

    .header_logo>img {
      width: 190px;
    }
  }