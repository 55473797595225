.top {
  border-radius: 64px;
  background: var(--main-bg-color);
  margin: 0 50px;
  height: 980px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.shadow {
  background: rgba(41, 41, 41, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.top video { 
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1023px) {
  .top {
    height: auto;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 57px;
  }
}

.title {
  position: relative;
  text-align: center;
  padding-top: 3px;
  color: var(--main-text-color);
  font-family: PhosphateSolid;
  font-size: 130px;
  line-height: 100%;
  text-transform: uppercase;
  z-index: 99;
}

.title span {
  color:#94ADCA;
}

.subtitle {
  color: var(--main-text-color);
  text-align: center;
  font-family: Rubik;
  font-size: 60px;
  font-style: italic;
  font-weight: 400;
  line-height: 100%;
  text-transform: lowercase;
  z-index: 99;
}

@media screen and (max-width: 1023px) {
  .title {
    padding-top: 12px;
    color: #FFF;
    font-family: PhosphateSolid;
    font-size: 66.65px;
    line-height: 100%;
    text-transform: uppercase;
    text-align: center;
  }

  .title span {
    color:#94ADCA;
  }
  

  .subtitle {
    color: #FFF;
    text-align: center;
    font-family: Rubik;
    font-size: 29.777px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
  }
}

.button_container {
  display: flex;
  align-items: center;
  margin-top: 70px;
  z-index: 99;
}

@media screen and (max-width: 1023px) {
  .button_container {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

.order_button {
  height: 96px;
  cursor: pointer;
  display: flex;
  padding: 32px 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 300px;
  background: var(--button-order-bg-color);
  color: #F2E7F1;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  border: 0;
  z-index: 99;
  transition: background-color 1s ease-in-out;
}

@media screen and (max-width: 1023px) {
  .order_button {
    width: 340px;
    padding: 25px 50px;
    color: #FFF;
    font-family: Rubik;
    font-size: 19.11px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.764px;
    text-transform: uppercase;
  }
}

.order_button:hover {
  box-shadow: 0px 1px 100px 0px rgb(148, 173, 202);
  background-color: rgb(48, 173, 202);
  transition: 0.5s;
}

.order_button:not(:hover)  {
  transition: 0.5s;
}

.show_details_button {
  cursor: pointer;
  height: 60px;
  margin-left: 24px;
  display: flex;
  padding: 18px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 300px;
  background: #F2E7F1;
  color: #000;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  border: 0;
  z-index: 99;
}

.show_details_button:hover {
  box-shadow: 0px 1px 100px 0px rgb(255,255,255);
  transition: 0.5s;
}

.show_details_button:not(:hover)  {
  transition: 0.5s;
}

@media screen and (max-width: 1023px) {
  .show_details_button {
    display: inline-flex;
    padding: 25.48px 50.96px;

    margin-left: 0;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
    gap: 7.963px;

    color: #000;
    font-family: Rubik;
    font-size: 14.333px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.573px;
    text-transform: uppercase;
  }
}

.info {
  display: flex;
  justify-content: right;
  margin-top: 100px;
  width: 100%;
  z-index: 99;
}

@media screen and (max-width: 1023px) {
  .info {
    display: none;
  }
}

.info_text {
  padding: 20px 24px;
  margin-right: 44px;
  border-radius: 32px;
  background: #F2E7F1;
  width: 777px;
  color: #14140C;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  z-index: 99;
}



#img_branch_top {
  position: absolute;
  top: 0;
  left: 70px
}

#img_branch_bottom {
  position: absolute;
  bottom: 0;
  left: 0
}

#img_bag {
  position: absolute;
  top: 146px;
  right: 62px;
  /* z-index: -1; */
  z-index: 10;
}

#image_top_left {
  position: absolute;
  top: 0;
  left: 0;
}

#image_top_center_1 {
  position: absolute;
  top: 0;
  left: 714px;
}

#image_top_center_2 {
  position: absolute;
  top: 33px;
  right: 510px;

}

#image_top_right {
  position: absolute;
  top: 0;
  right: 100px;
  z-index: 11;
}

#image_top_right_2 {
  position: absolute;
  top: 65px;
  right: 42px;
  z-index: 11;
}

#image_center {
  position: absolute;
  top: 330px;
  right: 373px;
  z-index: 11;
}

#image_bottom_left {
  position: absolute;
  bottom: 0;
  left: 100px;
}

#image_bottom_center_1 {
  position: absolute;
  bottom: 36px;
  left: 710px;
}

#image_bottom_center_2 {
  position: absolute;
  bottom: 86px;
  left: 750px;
}

#image_bottom_right {
  position: absolute;
  bottom: 0;
  right: 146px;
  z-index: 11;
}

#image_mobile_left_1 {
  display: none;
}

#image_mobile_left_2 {
  display: none;
}

#image_mobile_right_1 {
  display: none;
}

#image_mobile_right_2 {
  display: none;
}

#image_mobile_top_1 {
  display: none;
}

#image_mobile_top_2 {
  display: none;
}

@media screen and (max-width: 1023px) {

  #img_branch_top {
    display: none;
  }

  #img_branch_bottom {
    display: none;
  }

  #img_bag {
    top: auto;
    right: auto;
    position: relative;
    z-index: 10;
    width: 300px;
  }

  #image_top_left {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  #image_top_center_1 {
    position: absolute;
    top: 0;
    left: 714px;
    display: none;
  }

  #image_top_center_2 {
    position: absolute;
    top: 33px;
    right: 510px;
    display: none;
  }

  #image_top_right {
    position: absolute;
    top: 0;
    right: 100px;
    z-index: 11;
    display: none;
  }

  #image_top_right_2 {
    position: absolute;
    top: 65px;
    right: 42px;
    z-index: 11;
    display: none;
  }

  #image_center {
    position: absolute;
    top: 330px;
    right: 373px;
    z-index: 11;
    display: none;
  }

  #image_bottom_left {
    position: absolute;
    bottom: 0;
    left: 100px;
    display: none;
  }

  #image_bottom_center_1 {
    position: absolute;
    bottom: 36px;
    left: 710px;
    display: none;
  }

  #image_bottom_center_2 {
    position: absolute;
    bottom: 86px;
    left: 750px;
    display: none;
  }

  #image_bottom_right {
    position: absolute;
    bottom: 0;
    right: 146px;
    z-index: 11;
    display: none;
  }

  #image_mobile_left_1 {
    position: absolute;
    left: 0;
    top: 70px;
    z-index: 11;
    display: block;
  }

  #image_mobile_left_2 {
    position: absolute;
    left: 0;
    bottom: 150px;
    z-index: 11;
    display: block;
  }

  #image_mobile_right_1 {
    position: absolute;
    right: 0;
    top: 300px;
    z-index: 11;
    display: block;
  }

  #image_mobile_right_2 {
    position: absolute;
    right: 0;
    bottom: 25px;
    z-index: 11;
    display: block;
  }

  #image_mobile_top_1 {
    position: absolute;
    right: 100px;
    top: 0;
    z-index: 11;
    display: block;
  }

  #image_mobile_top_2 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    display: block;
  }
}