.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 101;
    overflow-y: hidden;
    user-select: none;
}
  
  
  
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102;
  }
  
.modal_container {
    position: fixed;
    display: flex;
    max-width: 400px;
    flex-direction: column;
    align-items: center;
  
    border-radius: 12px;
    background: var(--base-white, #FFF);
    padding: 24px;
  }
  
.button_container {
    width: 100%;
    display: flex;
    justify-content: right;
}
  
.close_button_container {
    cursor: pointer;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  
    border-radius: 29px;
    background: var(--grey-medium, #E5E5E5);
}

.modal_body {
    width: 100%;
    margin-top: 8px;
}