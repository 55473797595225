footer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  height: 219px;
  background: #F2F2F2;
  z-index: 20;
  padding-top: 28px;
}

.phone {
  display: none;
}

@media screen and (max-width: 1023px) {
  footer {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .logo {
    margin-top: 30px;
    margin-bottom: 70px;
  }

  .phone {
    display: block;
    margin-top: 17px;
    color: #14140C;
    font-family: Open Sans;
    font-size: 27.849px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
  }
}

#image_branch_left_1 {
  position: absolute;
  top: 950px;
  left: 0;
  z-index: 10;
}

#image_branch_left_2 {
  position: absolute;
  top: 850px;
  left: 0;
  z-index: 10;
}

#image_branch_right {
  position: absolute;
  top: 800px;
  right: 0;
  z-index: 10;
}

#image_left_1 {
  position: absolute;
  top: 1100px;
  left: 0;
  z-index: 10;
}

#image_left_2 {
  position: absolute;
  top: 1800px;
  left: 0;
  z-index: 10;
}

#image_left_3 {
  position: absolute;
  top: 2400px;
  left: 0;
  z-index: 10;
}

#image_right_1 {
  position: absolute;
  top: 1100px;
  right: 0;
  z-index: 10;
}

#image_right_2 {
  position: absolute;
  top: 1800px;
  right: 0;
  z-index: 10;
}

#image_right_3 {
  position: absolute;
  top: 2450px;
  right: 0;
  z-index: 10;
}

@media screen and (max-width: 1023px) {
  #image_branch_left_1 {
    display: none;
  }

  #image_branch_left_2 {
    display: none;
  }

  #image_branch_right {
    display: none;
  }

  #image_left_1 {
    display: none;
  }

  #image_left_2 {
    display: none;
  }

  #image_left_3 {
    display: none;
  }

  #image_right_1 {
    display: none;
  }

  #image_right_2 {
    display: none;
  }

  #image_right_3 {
    display: none;
  }
}