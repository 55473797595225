.order_details {
  margin-top: 85px;
}

.name_details {
  color: #94ADCA;
  font-family: Rubik;
  font-size: 27.822px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.subtitle_details {
  margin-top: 5px;
  color: #4E4E4E;
  font-family: Rubik;
  font-size: 17.389px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 4.173px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.span_details {
  display: block;
  margin-bottom: 15px;
  color: #14140C;
  font-family: Inter;
  font-size: 16.229px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

li {
  color: #14140C;
  font-family: Inter;
  font-size: 16.229px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}


@media screen and (max-width: 1023px) {
  .order_details {
    max-width: 460px;
    margin: 0 auto;
    margin-top: 55px;
  }
}

.container {
  text-align: center;
}

.title {
  color: #14140C;
  text-align: center;
  font-family: Rubik;
  font-size: 66.318px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
}

@media screen and (max-width: 1023px) {
  .title {
    color: #14140C;
    text-align: center;
    font-family: Rubik;
    font-size: 40.193px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.list_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.list {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(3, 460px);
  grid-template-rows: repeat(3, 460px);
  margin-top: 92px;
  list-style-type: none;
}


@media screen and (max-width: 1500px) {
  .list {
    margin-top: 52px;
    grid-template-columns: 460px 460px;
    grid-template-rows: repeat(5, 460px);
  }
}


@media screen and (max-width: 1023px) {
  .list {
    gap: 20px;
    grid-template-columns: 460px;
    grid-template-rows: repeat(9, 460px);
    margin: 52px auto 0 auto;
    padding: 0;
    /* width: calc(100% - 16px*2); */
  }
}

@media screen and (max-width: 460px) {
  .list {
    gap: 20px;
    grid-template-columns: 300px;
    grid-template-rows: repeat(9, 350px);
    margin: 52px auto 0 auto;
    padding: 0;
    /* width: calc(100% - 16px*2); */
  }
}