.product {
  position: relative;
  width: 100%;
  border-radius: 74.191px;
  background-color: #F2F2F2;
  overflow: hidden;
  z-index: 11;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.product:hover {
  background-color: var(--main-bg-color);
}

.box>.subtitle {
  transition: all 1s ease-in-out;
  color: #4E4E4E;
}

.product>.box>.title {
  transition: all 1s ease-in-out;
}

.product>.box>.text {
  transition: all 1s ease-in-out;
}

.product:hover>.box>.title {
  color: #FFF;
}

.product:hover>.box>.text {
  color: #FFF;
}

.product:hover>.box>.subtitle {
  color: #5CC651;
}

.product_img {
  position: absolute;
  bottom: 0;
  right: 0;

}

.box {
  width: 250px;
  margin-top: 40px;
  margin-left: 56px;
  z-index: 11;
}

.title {
  color: #14140C;
  font-family: Rubik;
  font-size: 27.822px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
}

.subtitle {
  margin-top: 5px;
  color: #4E4E4E;
  font-family: Rubik;
  font-size: 17.389px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 4.173px;
  text-transform: uppercase;
}

.text {
  margin-top: 28px;
  color: #14140C;
  font-family: Inter;
  font-size: 16.229px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 460px) {
  .box {
    width: 140px;
    margin-top: 45px;
    margin-left: 45px;
  }

  .title {
    color: #14140C;
    font-family: Rubik;
    font-size: 17.965px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
  }

  .subtitle {
    margin-top: 5px;
    color: #4E4E4E;
    font-family: Rubik;
    font-size: 11.228px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    letter-spacing: 2.695px;
    text-transform: uppercase;
  }

  .text {
    margin-top: 28px;
    color: #14140C;
    font-family: Inter;
    font-size: 10.479px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .product_img {
    width: 145px;
  }

}